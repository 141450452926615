<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account></account-settings-account>
      </v-tab-item>

      <v-tab-item>
        <account-settings-security></account-settings-security>
      </v-tab-item>
      <v-tab-item>
        <account-settings-apparence></account-settings-apparence>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiBrush,
} from '@mdi/js'

import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
import AccountSettingsApparence from './AccountSettingsApparence.vue'

// import AccountSettingsInfo from './AccountSettingsInfo.vue'

export default {

  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsApparence,

    // AccountSettingsInfo,
  },
  data: () => ({
    tab: '',
    tabs: [
      { title: 'Utilisateur', icon: mdiAccountOutline },
      { title: 'Sécurité', icon: mdiLockOpenOutline },
      { title: 'Apparence', icon: mdiBrush },
    ],
    icons: {
      mdiAccountOutline,
      mdiLockOpenOutline,
      mdiInformationOutline,
      mdiBrush,
    },
  }),
  mounted() {
    this.$store.dispatch('getInfos')
  },
}
</script>
