<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <UserAvatarVue
        :size="'120px'"
        class="me-6"
        :user="$store.state.user"
      ></UserAvatarVue>

      <!-- upload photo -->
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.$refs.input.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Nouvelle photo</span>
        </v-btn>

        <v-file-input
          ref="refInputEl"
          v-model="newProfilImg"
          class="d-none"
          label="Mettre à jour la photo "
          @change="(e) => fileName =e.name"
        ></v-file-input>

        <v-btn
          color="error"
          outlined
          class="mt-5"
          @click.prevent="removeAvatar"
        >
          Réinitialiser photo
        </v-btn>
        <p class="text-sm mt-5">
          Formats autorisés:  JPG, JPEG et PNG
        </p>
        <v-chip
          v-if="fileName.length > 0"
          class="ma-2"
          close
          outlined
          color="primary"
          @click:close="removeFile"
        >
          {{ fileName }}
        </v-chip>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="userInfos.displayName"
              label="Nom d'affichage"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="userInfos.username"
              label="Nom d'utilisateur"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userInfos.email"
              label="E-mail"
              dense
              outlined
            >
              <v-icon
                v-if="userInfos.confirmed"
                slot="append"
                color="success"
              >
                {{ icons.mdiCheckCircleOutline }}
              </v-icon>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="$store.state.user.status"
              dense
              outlined
              disabled
              label="Status"
              :items="status"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userInfos.phone"
              label="Téléphone"
              dense
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="userInfos.jobTitle"
              label="Intitulé du poste"
              dense
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <!-- alert -->
          <v-col
            v-if="!userInfos.confirmed"
            cols="12"
          >
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Votre e-mail n'est pas confirmée. Vérifiez votre boite de réception.
                  </p>
                  <a
                    class="text-decoration-none warning--text"
                    @click="sendVerification"
                  >
                    <span class="text-sm">Renvoyer la confirmation</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>
          <!-- END ALERT -->
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider class="my-4"></v-divider>

    <v-card-actions class="d-flex justify-end mt-5">
      <v-btn
        type="reset"
        outlined
        class="mx-2"
        @click.prevent="resetForm"
      >
        Annuler
      </v-btn>
      <v-btn
        color="primary"
        :disabled="btnDisable"
        @click="updateUser"
      >
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiCheckCircleOutline, mdiRefresh,
} from '@mdi/js'
import UserAvatarVue from '@/components/UserAvatar.vue'

export default {
  components: {
    UserAvatarVue,
  },

  data: () => ({
    displayEmailSignature: 'render',
    btnDisable: false,
    newProfilImg: null,
    disableConfirm: false,
    fileName: '',
    userInfos: {
      verified: false,
      displayName: '',
      email: '',
      username: '',
      confirmed: false,
      emailSignature: '',
      phone: '',
      jobTitle: '',
    },
    status: ['admin', 'tuteur', 'editeur'],

    icons: {
      mdiAlertOutline,
      mdiCloudUploadOutline,
      mdiCheckCircleOutline,
      mdiRefresh,
    },
  }),
  computed: {
    changed() {
      return !(this.userInfos.displayName === this.$store.state.user.displayName
      && this.userInfos.email === this.$store.state.user.email
      && this.userInfos.username === this.$store.state.user.username
      && this.userInfos.phone === this.$store.state.user.phone
      && this.userInfos.jobTitle === this.$store.state.user.jobTitle
      && this.newProfilImg === null)
    },
  },
  mounted() {
    this.syncData()
  },
  methods: {
    syncData() {
      this.$store.dispatch('getInfos')
        .then(() => {
          this.userInfos.displayName = this.$store.state.user.displayName
          this.userInfos.email = this.$store.state.user.email
          this.userInfos.username = this.$store.state.user.username
          this.userInfos.avatar = this.$store.state.user.avatar
          this.userInfos.confirmed = this.$store.state.user.confirmed
          this.userInfos.phone = this.$store.state.user.phone
          this.userInfos.jobTitle = this.$store.state.user.jobTitle
        })
    },
    removeFile() {
      this.newProfilImg = null
      this.fileName = ''
    },
    sendVerification() {
      if (!this.disableConfirm) {
        this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/send-email-verification`, { email: this.userInfos.email })
          .then(res => {
            if (res.status === 200) {
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Le mail de confirmation a été envoyé',
                value: true,
              })
              this.disableConfirm = true
            }
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la suppression de l\'image',
              value: true,
            })
          })
      }
    },
    removeAvatar() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/user/updateme`, { data: { avatar: null } })
        .then(() => {
          this.syncData()

          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Photo supprimée avec succès',
            value: true,
          })
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression de l\'image',
            value: true,
          })
        })
    },
    updateUser() {
      if (this.newProfilImg !== null) {
        const formData = new FormData()
        formData.append('files', this.newProfilImg, this.newProfilImg.name)
        this.$http.post(`${process.env.VUE_APP_API_URL}/upload`, formData)
          .then(response => {
            this.userInfos.avatar = response.data[0].id
            this.updateUserCallback()
          })
          .catch(error => console.error(error))
      } else {
        this.updateUserCallback()
      }
    },
    updateUserCallback() {
      if (this.changed) {
        this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/user/updateme`, { data: this.userInfos })
          .then(response => {
            if (response.status === 200) {
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Profil mis à jour',
                value: true,
              })
              this.newFiles = []
              this.fileName = ''
              this.syncData()
            }
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la mise à jour du profil',
              value: true,
            })
          })
      }
    },
    updatedSignature(event) {
      console.log(event)
    },
    restoreSignature() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/options`)
        .then(res => {
          this.userInfos.emailSignature = res.data.companyVisuals.emailSignature
          console.log(res)
        })
        .catch(err => console.log(err))
        .finally()
    },
  },
}
</script>
