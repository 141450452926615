<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiMail }}
      </v-icon>
      <span class="text-break">Signature d'e-mail</span>
    </v-card-title>
    <v-card-text class="d-flex">
      <v-form>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <label for="displayName">Nom d'affichage</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="displayName"
              v-model="userInfos.displayNameEmail"
              outlined
              dense
              placeholder="Jean-Pierre D."
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="jobTitle">Intitulé du poste</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="jobTitle"

              v-model="userInfos.jobTitle"
              outlined
              dense
              placeholder="Assistant administratif"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label for="phone">Téléphone</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              id="phone"
              v-model="userInfos.phone"
              outlined
              dense
              placeholder="06 12 34 56 78"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <label for="preview">Pré-visualisation</label>
          </v-col>

          <v-col
            cols="12"
            md="9"
          >
            <div
              v-html="renderedSignature"
            ></div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider class="my-4"></v-divider>

    <v-card-actions class="d-flex justify-end mt-5">
      <v-btn
        type="reset"
        outlined
        class="mx-2"
        @click.prevent="resetForm"
      >
        Annuler
      </v-btn>
      <v-btn
        color="primary"
        @click="updateUser"
      >
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiMail } from '@mdi/js'

export default {
  data() {
    return {
      userInfos: {
        displayNameEmail: '',
        jobTitle: '',
        phone: '',
      },
      businessWebsite: '',
      businessSignature: {
        logo: {
          url: '',
        },
      },
      signatureTemplate: `<div>
                        <table>
                            <tr>
                                <td style="padding: 7px">
                                    <img src="src_to_replace"
                                        alt="" width="200" height="80"
                                        style="display:block; border-radius: 50%; margin-right: 7px; margin-top:20px; float: left">
                                    <div style="width: 5px; height: 120px; background:color_to_replace; float: right">
                                </td>
                                <td style="vertical-align:top; padding:25px 14px 7px 3px">
                                    <strong
                                        style="margin: 0; font-size:18px; color: #000000; line-height: 24px; height: 24px; display:block">display_name_to_replace</strong>
                                    <p style='font-size:16px; margin: 0px 0 6px;'>
                                        <span style="margin: 0; color: #000">job_title_to_replace</span>
                                    </p>
                                    <p style='font-size:14px; margin: 0px 0 6px;'>
                                        <a style="text-decoration: none;" href="tel:phone_to_replace"><span style="margin: 0; color: #000">Tel: phone_to_replace</span></a>
                                    </p>
                                    <p style='font-size:14px; margin: 0px 0 6px;'>
                                        <a style="text-decoration: none;" href="website_to_replace"><span style="margin: 0; color: #000">website_to_replace</span></a>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>`,
      icons: {
        mdiMail,
      },
    }
  },
  computed: {
    renderedSignature() {
      const template = this.signatureTemplate
      const logoUrl = `${process.env.VUE_APP_API_URL}${this.businessSignature.logo.url}`
      const mapObj = {
        src_to_replace: logoUrl,
        color_to_replace: this.businessSignature.color,
        display_name_to_replace: this.userInfos.displayNameEmail,
        job_title_to_replace: this.userInfos.jobTitle,
        phone_to_replace: this.userInfos.phone,
        website_to_replace: this.businessWebsite,
      }
      const reg = new RegExp(Object.keys(mapObj).join('|'), 'gi')
      const signature = template.replace(reg, matched => mapObj[matched])

      return signature
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchOptions()])
        .then(values => {
          const [options] = values
          this.businessSignature = options.emailSignature
          this.businessWebsite = options.company.website
        })
      this.$store.dispatch('getInfos')
        .then(() => {
          this.userInfos = {
            displayNameEmail: this.$store.state.user.displayNameEmail,
            jobTitle: this.$store.state.user.jobTitle,
            phone: this.$store.state.user.phone,
          }
        })
    },
    async fetchOptions() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/options`)

      return res.data
    },
    updateUser() {
      const userinfos = {
        phone: this.userInfos.phone,
        emailSignature: this.renderedSignature,
        jobTitle: this.userInfos.jobTitle,
        displayNameEmail: this.userInfos.displayNameEmail,
      }
      this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/user/updateme`, { data: userinfos })
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Signature mise à jour avec succès.',
            value: true,
          })
          this.$store.dispatch('getInfos')
        })
        .catch(err => console.log(err))
        .finally()
    },
  },
}
</script>

<style>

</style>
