<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :rules="[v => !!v || 'Le mot de passe est requis']"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Mot de passe actuel"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :rules="[v => !!v || 'Requis',(newPassword === cPassword) || 'Les mots de passe doivent correspondre']"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Nouveau mot de passe"
                outlined
                dense
                hint="Le mot de passe doit faire au moins 8 caractères."
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :rules="[v => !!v || 'Requis',(newPassword === cPassword) || 'Les mots de passe doivent correspondre']"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirmer le nouveau mot de passe"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="400"
                src="@/assets/images/illustrations/password_reset_fe.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Authentication à deux facteurs </span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-avatar
            color="primary"
            class="primary mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="white"
            >
              {{ icons.mdiLockOpenOutline }}
            </v-icon>
          </v-avatar>
          <p class="text-base text--primary font-weight-semibold">
            L'authentification à deux facteurs n'est pas encore activée.
          </p>
          <p class="text-sm text--primary">
            L'authentification à deux facteurs ajoute une couche supplémentaire de
            sécurité à votre compte en exigeant plus qu'un simple mot de passe pour se connecter. En savoir plus.
          </p>
        </v-card-text>

        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            :disabled="!valid"
            @click="updatePassword"
          >
            Enregistrer
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
            @click="reset"
          >
            Annuler
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  data() {
    return {
      disableBtn: false,
      rules: {
        required: value => !!value || 'Le champs est requis.',
        matching: (this.newPassword === this.cPassword) || 'Les mots de passe doivent correspondre.',
      },
      valid: true,
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      currentPassword: '',
      newPassword: '',
      cPassword: '',
    }
  },
  computed: {

  },
  methods: {
    reset() {
      this.currentPassword = ''
      this.newPassword = ''
      this.cPassword = ''
    },
    updatePassword() {
      if (this.$refs.form.validate()) {
        this.disableBtn = true
        this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/password-update/`, { password: this.currentPassword, newPassword: this.newPassword })
          .then(res => {
            if (res.status === 200) {
              this.currentPassword = ''
              this.newPassword = ''
              this.cPassword = ''
              this.$refs.form.reset()
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Mot de passe mis à jour',
                value: true,
              })
            }
          })
          .catch(err => {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la mise à jour du mot de passe',
              value: true,
            })
            console.log(err)
          })
          .finally(() => {
            this.disableBtn = false
          })
      } else {
        this.$refs.form.validate()
      }
    },
  },
  setup() {
    return {
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
